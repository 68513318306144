import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";

// couriers
import alpa from "./assets/couriers/alpa_icon.png";
import dhl from "./assets/couriers/dhl_icon.png";
import estafeta from "./assets/couriers/estafeta_icon.png";
import fedex from "./assets/couriers/fedex_icon.png";
import flecha_amarilla from "./assets/couriers/flecha_amarilla_icon.png";
import odm from "./assets/couriers/odm_icon.png";
import paquete_express from "./assets/couriers/paquete_express_icon.png";
import redpack from "./assets/couriers/redpack_icon.png";

// integrations
import api from "./assets/integrations/api_icon.png";
import magento from "./assets/integrations/magento_icon.png";
import mercado_libre from "./assets/integrations/mercado_libre_icon.png";
import prestashop from "./assets/integrations/prestashop_icon.png";
import shopify from "./assets/integrations/shopify_icon.png";
import woocommerce from "./assets/integrations/woocommerce_icon.png";

// asesorias
import asesoria_circles from "./assets/asesoria_circles.png";
import asesoria_phone from "./assets/asesoria_phone.png";

// experiencias
import experiencia_points from "./assets/experiencia_points.png";

// footer
import facebook from "./assets/socials/fb_logo.png";
import inkedin from "./assets/socials/inkedin_logo.png";
import instagram from "./assets/socials/insta_logo.png";

import logo from "./assets/logo.svg";
import logistica from "./assets/logistica.png";
import container from "./assets/container.png";
import card from "./assets/card.png";
import carga from "./assets/carga.png";
import portafolio from "./assets/portafolio.png";
import solucion from "./assets/solucion.png";
import asesoria from "./assets/asesoria.png";
import experiencia from "./assets/experiencia.png";
import footer from "./assets/footer.png";
import cliente1 from "./assets/cliente1.png";
import cliente2 from "./assets/cliente2.png";
import cliente3 from "./assets/cliente3.png";
import star from "./assets/icons/star.png";
import portafolio_quote from "./assets/portafolio_quote.png";
import solutions_woman from "./assets/solutions_woman.png";
import "./App.css";

function App() {
  return (
    <>
      <Container>
        <Row className="header">
          <Col xs>
            <img src={logo} alt="logo" />
          </Col>
          <Col className="header-option d-xs-none text-right" xs>
            <a href="">Productos</a>
          </Col>
          <Col className="header-option d-xs-none" xs>
            <a href="">Integraciones</a>
          </Col>
          <Col className="header-option d-xs-none text-left" xs>
            <a href="">Nosotros</a>
          </Col>
          <Col className="d-grid gap-2" xs>
            <Button variant="outline-dark">Registrarme</Button>
          </Col>
          <Col className="header-login d-grid gap-2" xs>
            <Button variant="dark">Iniciar sesión</Button>
          </Col>
        </Row>

        {/* Principal */}
        <Row className="principal-content">
          <Col className="principal-left-content" sm={6} xs={12}>
            <h1>
              Gestiona la logística <br />
              de tu negocio en una <br />
              sola plataforma
            </h1>
            <p className="principal-left-p">
              Con envipaq puedes gestionar de forma rápida y <br />
              sencilla la administración de la logística de tu negocio.
            </p>
            <Row>
              <Col className="d-grid gap-2" sm={6} xs={12}>
                <Button variant="dark">Empezar ahora</Button>
              </Col>
              <Col className="d-grid gap-2" sm={6} xs={12}>
                <Button variant="outline-dark">Contactar</Button>
              </Col>
              <Col className="d-grid gap-2 d-xs-none" xs></Col>
            </Row>
          </Col>
          <Col sm={6} xs={12}>
            <img
              src={logistica}
              className="principal-right-img"
              alt="logistica"
            />
          </Col>
        </Row>

        {/* Secundary */}
        <Row>
          <Col xs>
            <h1>
              La plataforma que solucionará <br />
              tu proceso logístico
            </h1>
            <p className="principal-left-p">
              Envipaq reúne en una plataforma todo lo que necesitas para
              servicios logísticos, <br />
              almacenaje, distribución y servicios aduanales. Proveemos
              soluciones en carga, <br />
              descarga, manejo y despacho de mercancías.
            </p>
          </Col>
        </Row>

        {/* Cards */}
        <Row>

          <Col xs={12} md={12} lg={4} xl={4} className="card-container mb-5">
            <Col className="card">
              <img src={card} alt="card" />
              <h2>Paquetería y mensajería</h2>
              <p>
                <div>
                  Genera, administra y rastrea tus guías de la forma más fácil y
                  segura.
                </div>
                Servicio nacional e internacional con las tarifas más
                competitivas.
              </p>
            </Col>
          </Col>

          <Col xs={12} md={12} lg={4} xl={4} className="card-container mb-5">
            <Col className="card">
              <img src={container} alt="container" />
              <h2>Logística</h2>
              <p>
                <div>
                  Flete internacional aéreo, marítimo (LCL y FCL) y terrestre.
                </div>
                Contamos con los mejores agentes en el extranjero, siempre
                buscando brindarte soluciones de transporte en todas sus
                modalidades fuera de México.
              </p>
            </Col>
          </Col>

          <Col xs={12} md={12} lg={4} xl={4} className="card-container mb-5" >
            <Col className="card">
              <img src={carga} alt="carga" />
              <h2>Carga</h2>
              <p>
                <div>
                  Desde el almacén hasta la entrega del producto a tu cliente.
                </div>
                Picking, Packing, embalaje , servicio perzonalizado, seguridad y
                más.
              </p>
            </Col>
          </Col>
          <Row className="card-button-container">
          <Col className="buttons-container-mobile mt-5">
            <Button
              variant="dark"
              className="start-button-mobile text-center"
            >
              Empezar ahora
            </Button>
            <Button
              variant="white"
              className="contact-button-mobile"
            >
              Contactar
            </Button>
          </Col>
          </Row>
        </Row>

        {/* Clients */}
        <Row className="testimonios">
          <Col xs={12} sm={12} md={12} lg={5} xl={6}>
            <h1>
              Clientes que <br />
              confian en envipaq
            </h1>
            <Button variant="light" className="testimonios-web-button">
              Empezar ahora
            </Button>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} xl={6}>
            <Row className="testimonio-cliente">
              <Col xs={4} className="testimonio-cliente-img">
                <img src={cliente1} alt="testimonio-cliente"></img>
              </Col>
              <Col xs={8} className="testimonio-cliente-info">
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <p>
                  “Envipaq optimiza nuestro <br /> proceso de envío a tiendas y
                  <br />
                  clientes. Es esencial para nuestra
                  <br /> estrategía omnicanal.”
                </p>
              </Col>
            </Row>

            <Row className="testimonio-cliente margin-left-110">
              <Col xs={4} className="testimonio-cliente-img">
                <img src={cliente2} alt="testimonio-cliente"></img>
              </Col>
              <Col xs={8} className="testimonio-cliente-info ">
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <p>
                  “Convirtió el proceso logístico en
                  <br /> una experiencia agradable para
                  <br /> nuestros clientes. Además la
                  <br /> plataforma es muy intuitiva.”
                </p>
              </Col>
            </Row>

            <Row className="testimonio-cliente margin-left-210">
              <Col xs={4} className="testimonio-cliente-img">
                <img src={cliente3} alt="testimonio-cliente"></img>
              </Col>
              <Col xs={8} className="testimonio-cliente-info ">
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <img
                  src={star}
                  alt="star"
                  className="testimonio-cliente-info-stars"
                ></img>
                <p>
                  “Nuestro equipo tardaba mucho
                  <br /> tiempo en crear etiquetas cada
                  <br /> día. Por fin la logística es fácil y
                  <br /> sencilla.”
                </p>
              </Col>
              <Col xs={12} className="text-center">
                <Button
                  variant="white"
                  className="testimonio-mobile-start-button"
                >
                  Empezar ahora
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Portafolio */}
        <Row className="portafolio">
          <Col    xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}>
            <h1 className="portafolio-title-mobile">
              El portafolio de
              <br /> integraciones más grande
            </h1>

            <h1 className="portafolio-title-web">
              El portafolio de integraciones
              <br />
              más completo
            </h1>

            <p className="portafolio-principal-left-p-mobile">
              Conéctate al mayor portafolio de <br /> integraciones del mercado
              en unos cuantos
              <br /> clics y expande tu marca en el mercado
              <br /> internacional. ¿Listo para crecer <br />
              exponencialmente?
            </p>

            <p className="portafolio-principal-left-p-web">
              Conéctate al mayor portafolio de integraciones del mercado en unos
              cuantos clics
              <br /> y expande tu marca en el mercado internacional. ¿Listo para
              crecer
              <br /> exponencialmente?
            </p>

            <div className="portafolio-input-container-web">
              <InputGroup className="portafolio-email-container-web mb-3">
                <FormControl
                  className="portafolio-email-web"
                  placeholder="Correo electrónico"
                  aria-label="Correo electrónico"
                  aria-describedby="portafolio-email"
                />
              </InputGroup>
              <Button variant="dark" className="portafolio-email-button-web">
                Empezar
              </Button>
            </div>

            <div className="portafolio-input-container-mobile">
              <InputGroup className="portafolio-email-container-mobile mb-3">
                <FormControl
                  className="portafolio-email-mobile"
                  placeholder="Correo electrónico"
                  aria-label="Correo electrónico"
                  aria-describedby="portafolio-email"
                />
              </InputGroup>
              <Button variant="dark" className="portafolio-email-button-mobile">
                Empezar
              </Button>
            </div>

            <Row className="portafolio-integrations-container">
              <Col xs={7} sm={6} md={4} lg={3} xl={3} className="mb-5">
                <img src={prestashop}></img>
              </Col>
              <Col xs={5} sm={4} md={4} lg={2} xl={2} className="mb-5">
                <img src={shopify}></img>
              </Col>
              <Col xs={1} sm={2} md={4} lg={1} xl={1} className="mb-5">
                <img src={api}></img>
              </Col>
              <Col xs={5} sm={5} md={4} lg={2} xl={2} className="mb-5">
                <img src={mercado_libre}></img>
              </Col>
              <Col xs={3} sm={3} md={4} lg={1} xl={1} className="mb-5">
                <img src={woocommerce}></img>
              </Col>
              <Col xs={4} sm={4} md={4} lg={2} xl={2} className="mb-5">
                <img src={magento}></img>
              </Col>
            </Row>
            <Row className="portafolio-couriers-container">
              <Col xs={6} sm={5} md={4} lg={3} xl={1} className="mb-5">
                <img src={estafeta}></img>
              </Col>
              <Col xs={6} sm={4} md={4} lg={3} xl={1} className="mb-5">
                <img src={paquete_express}></img>
              </Col>
              <Col xs={6} sm={3} md={4} lg={3} xl={1} className="mb-5">
                <img src={fedex}></img>
              </Col>
              <Col xs={6} sm={5} md={4} lg={3} xl={1} className="mb-5">
                <img src={redpack}></img>
              </Col>
              <Col xs={4} sm={3} md={4} lg={3} xl={1} className="mb-5">
                <img src={odm}></img>
              </Col>
              <Col xs={4} sm={4} md={4} lg={3} xl={1} className="mb-5">
                <img src={alpa}></img>
              </Col>
              <Col xs={4} sm={4} md={4} lg={3} xl={1} className="mb-5">
                <img src={flecha_amarilla}></img>
              </Col>
              <Col xs={4} sm={4} md={4} lg={3} xl={1} className="mb-5">
                <img src={dhl}></img>
              </Col>
            </Row>
            {/* <img src={portafolio} alt="portafolio" /> */}
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="portafolio-quote-right-mobile"
          >
            <h1 className="portafolio-quote-left-title">
              Compara costos en <br /> paquetería y ahorra en <br /> envíos
            </h1>
            <p className="portafolio-quote-left-p">
              En envipaq puedes cotizar con las distintas<br/> paqueterías y los
              diferentes servicios para<br/> optimizar la administración de
              gastos y<br/> ahorrar en envíos.
            </p>
          
          </Col>

          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="portafolio-quote-img-container"
          >
            <img src={portafolio_quote} className="portafolio-quote-img"></img>
          </Col>

          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="portafolio-quote-right-web"
          >
            <h1 clasName="portafolio-quote-left-title">
              Compara costos en <br /> paquetería y ahorra en <br /> envíos
            </h1>
            <p className="portafolio-quote-left-p">
              En envipaq puedes cotizar con las distintas paqueterías y los
              <br /> diferentes servicios para optimizar la administración de
              <br /> gastos y ahorrar en envíos.
            </p>
            <div className="portafolio-buttons-container">
            <Button
              variant="dark"
              className="portafolio-quote-start-button"
            >
              Empezar ahora
            </Button>
            <Button
              variant="white"
              className="portafolio-quote-contact-button"
            >
              Contactar
            </Button>
            </div>
          </Col>
          <Col className="buttons-container-mobile mt-5">
            <Button
              variant="dark"
              className="start-button-mobile text-center"
            >
              Empezar ahora
            </Button>
            <Button
              variant="white"
              className="contact-button-mobile"
            >
              Contactar
            </Button>
          </Col>
        </Row>

        {/* solucion */}
        <Row className="solucion">
          <Row className="solucion-container">
            <div className="solucion-line"></div>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={5}
              className="solucion-title-container"
            >
              <h1>
                Envipaq es la solución
                <br /> más completa para la
                <br /> logística de tu negocio
              </h1>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={5}
              className="solucion-title-container-mobile"
            >
              <h1>
                Envipaq es la solución más
                <br /> completa para la logística
                <br /> de tu negocio
              </h1>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={7}
              className="solucion-img-container"
            >
              <img src={solutions_woman} className="solucion-img-woman"></img>
            </Col>
          </Row>
          <Row className="solucion-pasos-container">
            <Col xs={12} sm={4} md={4} lg={4} xl={4}>
              <p className="solucion-pasos-title-etiquetado">Etiquetado</p>
              <p className="solucion-pasos-texts">
                En solo unos pasos podrás <br />
                automatizar tu proceso de envío y así <br /> evitar tareas
                manuales.
              </p>
            </Col>
            <Col xs={12} sm={4} md={4} lg={4} xl={4}>
              <p className="solucion-pasos-title-inventario">Inventario</p>
              <p className="solucion-pasos-texts">
                Inventario de tus artículos con acceso <br /> al sistema donde
                podrás consultar
                <br /> 24/7 la existencia de tus artículos.
              </p>
            </Col>
            <Col xs={12} sm={4} md={4} lg={4} xl={4}>
              <p className="solucion-pasos-texts margin-top-pasos">
                <p className="solucion-pasos-title-picking">
                  Picking and Packing
                </p>
                Almacenamiento y empaquetado de <br /> tus productos.
              </p>
            </Col>
          </Row>
        </Row>

        {/* asesoria */}
        <Row className="asesoria">
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <h1 className="text-center">
              Asesoramiento de <br />
              principio a fin
            </h1>
            <ul className="asesoria-list">
              <li>Asesor logístico asignado a tu cuenta.</li>
              <li>Seguimiento oportuno a tu operación.</li>
              <li>Servicio de recolección y empaque.</li>
              <li>Sistema de guías y control envipaq.</li>
              <div className="asesoria-line"></div>
            </ul>
            <div className="text-center">
              <Button variant="dark" className="asesoria-button">
                Comenzar ahora
              </Button>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="asesoria-images"
          >
            <img
              src={asesoria_circles}
              className="asesoria-images-circle"
            ></img>
            <img src={asesoria_phone} className="asesoria-images-phone"></img>
          </Col>
        </Row>

        {/* experiencia */}
        <Row className="experiencia">
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
            <img
              src={experiencia_points}
              className="experiencia-points-img"
            ></img>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <h1 className="experiencia-title">
              La experiencia de <br /> entrega más óptima
            </h1>
            <h1 className="experiencia-title-mobile text-center">
              La experiencia de entrega
              <br /> más óptima
            </h1>
            <p className="experiencia-p">
              Asesoramos y coordinamos la operación de tu proceso <br />
              logístico para asegurar el desaduanamiento correcto.
            </p>
            <p className="experiencia-p-mobile text-center">
              Asesoramos y coordinamos la operación de tu
              <br /> proceso logístico para asegurar el
              <br /> desaduanamiento correcto.
            </p>
            <div className="experiencia-button-container">
              <Button variant="dark" className="experiencia-start-button">
                Empezar ahora
              </Button>
              <Button variant="white" className="experiencia-contact-button">
                Contactar
              </Button>
            </div>

            <Col className="buttons-container-mobile ">
            <Button
              variant="dark"
              className="start-button-mobile text-center"
            >
              Empezar ahora
            </Button>
            <Button
              variant="white"
              className="contact-button-mobile"
            >
              Contactar
            </Button>
          </Col>
          </Col>
        </Row>
      </Container>

      {/* footer-web */}
      <Container className="container-footer">      
      <Row className="footer-web">
        <Col xs={12} sm={12} lg={5} xl={6} className="footer-left-container">
          <img src={logo} className="footer-logo"></img>
          <p className="footer-left-direction">
            © Copyright Envipaq 2021. Todos los
            <br /> derechos reservados
            <br /> Rumania 507 Col. Portales Norte, Del.
            <br /> Benito Juárez, CDMX
          </p>
          <div className="footer-left-socials-container">
            <div className="footer-left-socials-img">
              <img src={instagram}></img>
            </div>
            <div className="footer-left-socials-img">
              <img src={facebook}></img>
            </div>
            <div className="footer-left-socials-img">
              <img src={inkedin}></img>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} lg={7} xl={6} className="footer-right-container">
          <div className="footer-input-container">
            <InputGroup className="footer-email-container mb-3">
              <FormControl
                className="footer-email"
                placeholder="Correo electrónico"
                aria-label="Correo electrónico"
                aria-describedby="footer-email"
              />
            </InputGroup>
            <Button variant="dark" className="footer-email-button">
              Empezar
            </Button>
          </div>
          <div className="footer-right-links-container">
            <div className="mt-10-links">
              <a href="/">FAQ</a>
            </div>
            <div className="mt-10-links">
              <a href="/">Aviso de Privacidad</a>
            </div>
            <div className="mt-10-links">
              <a href="/">Términos y condiciones</a>
            </div>
          </div>
        </Col>
      </Row>

      {/* footer-mobile */}
      <Row className="footer-mobile">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          className="footer-left-container"
        >
          <img src={logo} className="footer-logo"></img>

          <div className="footer-input-container-mobile">
            <InputGroup className="footer-email-container-mobile mb-3">
              <FormControl
                className="footer-email-mobile"
                placeholder="Correo electrónico"
                aria-label="Correo electrónico"
                aria-describedby="footer-email"
              />
            </InputGroup>
            <Button variant="dark" className="footer-email-button-mobile">
              Empezar
            </Button>
          </div>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          className="footer-right-container"
        >
          <p className="footer-left-direction">
            © Copyright Envipaq 2021. Todos los
            <br /> derechos reservados
            <br /> Rumania 507 Col. Portales Norte, Del.
            <br /> Benito Juárez, CDMX
          </p>
          <div className="footer-left-socials-container">
            <div className="footer-left-socials-img">
              <img src={instagram}></img>
            </div>
            <div className="footer-left-socials-img">
              <img src={facebook}></img>
            </div>
            <div className="footer-left-socials-img">
              <img src={inkedin}></img>
            </div>
          </div>
          <div className="footer-right-links-container">
            <div className="mt-10-links">
              <a href="/">FAQ</a>
            </div>
            <div className="mt-10-links">
              <a href="/">Aviso de Privacidad</a>
            </div>
            <div className="mt-10-links">
              <a href="/">Términos y condiciones</a>
            </div>
          </div>
        </Col>
      </Row>
      </Container>
    </>
  );
}

export default App;
